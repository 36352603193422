<template>
  <div class="app-container user-destroy-list">
    <div v-scrollTop>
      <avue-crud
        ref="crud"
        class="search-form"
        v-model="searchForm"
        :option="tableOption"
        :data="tableData"
        :page="tablePage"
        :tableLoading="tableLoading"
        @size-change="sizeChange"
        @current-change="pageChange"
        @search-change="searchChange"
      >
        <template #menuLeft="scoped">
          <span style="display: block; font-size: 14px; padding-bottom: 10px"
            >已注销账号，后台仅保留订单管理，余额调整功能。</span
          >
        </template>

        <template #cancellation_timeSearchForm="{ form, prop }">
          <SaleDateTime :time.sync="form[prop]" @change="searchChange"></SaleDateTime>
        </template>

        <template #cancellation_time="{ row }">
          {{ parseTime(row.cancellationDate) }}
        </template>
        <!-- 分销商类型 -->
        <template #isProductPublic="{ row }">
          {{ row.isProductPublic === 0 ? '分销商' : '公共产品上新' }}
        </template>
        <template #expand="{ row }">
          <div v-if="expandData(row).length > 0" class="info-content">
            <CommonTable
              :cols="[
                {
                  prop: 'nickName',
                  label: '账号名称',
                  width: '180px'
                },
                {
                  prop: 'accountName',
                  label: '账号',
                  width: '180px'
                }
              ]"
              width="361px"
              height="240px"
              :selection="false"
              :infoData="row.childrenList"
            >
            </CommonTable>
          </div>
          <span v-else>该账号无子账号</span>
        </template>

        <template #menu="{ row }">
          <ColorTextBtn v-if="checkPermission('externaladmin:user:destroy:accountInfo')" class="line" @click="showInfo(row)"
            >账号信息
          </ColorTextBtn>
          <el-divider direction="vertical"></el-divider>
          <DeleteAccount :init="init" v-if="checkPermission('externaladmin:user:destroy:deleteAccount')" :row="row"></DeleteAccount>
        </template>
      </avue-crud>
    </div>
  </div>
</template>

<script>
import avueCrud from '@/mixins/avueCrud'
import { tableOption } from './const'
import SaleDateTime from '@/components/saleDateTime'
import DeleteAccount from './module/dialogs/deleteAccount'
import { cancellationUserList as getList } from '@/api/user'
import { parseTime } from '@/utils'

export default {
  mixins: [
    avueCrud({
      tableOption,
      getList
    })
  ],
  components: { SaleDateTime, DeleteAccount },

  data() {
    return {
      m_this: this,
      resetMergeData:{
        isCancellation:1
      }
    }
  },
  computed: {
    expandData() {
      return (row) => {
        return $GET(row, 'childrenList', [])
      }
    }
  },
  watch: {},
  methods: {
    parseTime,
    del(data) {
      this.dialogVisible = true
    },
    showInfo(row) {
      this.$router.push({
        path: '/user/destroy/info',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-destroy-list {
  .info-content {
    font-size: 14px;
  }
}
</style>
