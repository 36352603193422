var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container user-destroy-list"},[_c('div',{directives:[{name:"scrollTop",rawName:"v-scrollTop"}]},[_c('avue-crud',{ref:"crud",staticClass:"search-form",attrs:{"option":_vm.tableOption,"data":_vm.tableData,"page":_vm.tablePage,"tableLoading":_vm.tableLoading},on:{"size-change":_vm.sizeChange,"current-change":_vm.pageChange,"search-change":_vm.searchChange},scopedSlots:_vm._u([{key:"menuLeft",fn:function(scoped){return [_c('span',{staticStyle:{"display":"block","font-size":"14px","padding-bottom":"10px"}},[_vm._v("已注销账号，后台仅保留订单管理，余额调整功能。")])]}},{key:"cancellation_timeSearchForm",fn:function(ref){
var form = ref.form;
var prop = ref.prop;
return [_c('SaleDateTime',{attrs:{"time":form[prop]},on:{"update:time":function($event){return _vm.$set(form, prop, $event)},"change":_vm.searchChange}})]}},{key:"cancellation_time",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.parseTime(row.cancellationDate))+" ")]}},{key:"isProductPublic",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.isProductPublic === 0 ? '分销商' : '公共产品上新')+" ")]}},{key:"expand",fn:function(ref){
var row = ref.row;
return [(_vm.expandData(row).length > 0)?_c('div',{staticClass:"info-content"},[_c('CommonTable',{attrs:{"cols":[
              {
                prop: 'nickName',
                label: '账号名称',
                width: '180px'
              },
              {
                prop: 'accountName',
                label: '账号',
                width: '180px'
              }
            ],"width":"361px","height":"240px","selection":false,"infoData":row.childrenList}})],1):_c('span',[_vm._v("该账号无子账号")])]}},{key:"menu",fn:function(ref){
            var row = ref.row;
return [(_vm.checkPermission('externaladmin:user:destroy:accountInfo'))?_c('ColorTextBtn',{staticClass:"line",on:{"click":function($event){return _vm.showInfo(row)}}},[_vm._v("账号信息 ")]):_vm._e(),_c('el-divider',{attrs:{"direction":"vertical"}}),(_vm.checkPermission('externaladmin:user:destroy:deleteAccount'))?_c('DeleteAccount',{attrs:{"init":_vm.init,"row":row}}):_vm._e()]}}]),model:{value:(_vm.searchForm),callback:function ($$v) {_vm.searchForm=$$v},expression:"searchForm"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }