export const tableOption = {
  menuAlign: 'left',
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSize: 'small',
  border: true,
  expand: true,
  // align: 'center',
  column: [
    {
      label: '注销用户名',
      prop: 'nickName',
      search: true,
      searchLabelWidth: 100,
      searchWidth: 300
    },
    {
      label: '注销账号',
      prop: 'accountName',
      search: true,
      searchWidth: 300
    },
    {
      label: '余额',
      prop: 'balance',
      search: false
    },
    {
      label: '注销时间',
      prop: 'cancellation_time',
      addDisplay: false,
      editDisplay: false,
      search: true,
      searchSpan: 8,
      searchWidth: 500,
      searchFormSlot: true,
      slot: true
    },
    {
      label: '分销商类型',
      prop: 'isProductPublic',
      search: true,
      searchLabelWidth: 100,
      searchWidth: 300,
      type: 'select',
      dicData: [
        {
          value: undefined,
          label: '全部'
        },
        {
          value: 0,
          label: '分销商'
        },
        {
          value: 1,
          label: '公共产品上新'
        }
      ]
    }
  ]
}

export const historyOption = {
  menuAlign: 'left',
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSize: 'small',
  border: true,
  expand: false,
  // align: 'center',
  column: [
    {
      label: '操作时间',
      prop: 'tradeTime',
      search: false
    },
    {
      label: '变更金额',
      prop: 'tradeAmount',
      search: false
    },
    {
      label: '变更前余额',
      prop: 'beforeRemainingAmount',
      search: false
    },
    {
      label: '变更后余额',
      prop: 'remainingAmount',
      search: false
    },
    {
      label: '备注',
      prop: 'remark',
      search: false
    },
    {
      label: '行为',
      prop: 'tradeType',
      search: false
    }
  ]
}
